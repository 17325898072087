import Numbers from '../../../../helpers/numbers';

const useCalculatePreviousControlRows = ({
  filterBudgets,
  contractRowsForBudgetContol,
  findBudget,
  budgetRows
}) => {

  const calculatePercentage = (valueToPercentage, total) => {
    const percentageNumber = (valueToPercentage * 100) / total;
    return Numbers.toFixed(percentageNumber, 2, true);
  };

  const sumOfTotals = (budgets, newPreviousBudget = null) => {
    const newValueForBudget = budgets.reduce((acc, budget) => {
      acc += budget.previousBudget;

      return acc;
    }, newPreviousBudget || 0);

    return newValueForBudget;
  };

  const calculateTotalAndPercentage = (idsToFind, newRowState, rowId, BudgetToCalculate, totalForPercentage) => {
    const butgetsWithoutDuplicates = filterBudgets(idsToFind, newRowState, rowId);
    const newValueForBudget = sumOfTotals(butgetsWithoutDuplicates, newRowState.previousBudget);
    const percentage = calculatePercentage(newValueForBudget, totalForPercentage);

    return {
      ...BudgetToCalculate,
      previousBudget: newValueForBudget,
      budgetPercentage: percentage,
    };
  };

  const calculateUtility = (calculatedGroup, totalForPercentage, isTotalRow = false) => {
    const contractsToFind = contractRowsForBudgetContol.filter(
      (contract) => contract.id !== calculatedGroup.budgetId && contract.id !== '-200',
    );

    const contractsIds = contractsToFind.map((row) => row.id);

    if (calculatedGroup.budgetId !== 'financing') {
      contractsIds.unshift('financing');
    }

    const financialPerformance =
      calculatedGroup.budgetId !== 'financialPerformance'
        ? findBudget(budgetRows, 'financialPerformance', calculatedGroup?.budgetIndex)
        : calculatedGroup;

    const utilityBeforeTaxBudget = findBudget(budgetRows, 'UTILITY-BEFORE-TAX', calculatedGroup.budgetIndex);

    const budgetsFiltered = filterBudgets(contractsIds, utilityBeforeTaxBudget, utilityBeforeTaxBudget?.budgetId);

    if (!isTotalRow && calculatedGroup.budgetId !== 'financialPerformance') {
      budgetsFiltered.push(calculatedGroup);
    }

    const valuesToSubstractTotal = sumOfTotals(budgetsFiltered);

    const utilityBeforeTaxTotal = Number(
      totalForPercentage - valuesToSubstractTotal + (financialPerformance?.previousBudget || 0),
    );

    const utilityBeforeTaxPercentage = calculatePercentage(utilityBeforeTaxTotal, totalForPercentage);

    const newValueForUtility = {
      ...utilityBeforeTaxBudget,
      previousBudget: utilityBeforeTaxTotal,
      budgetPercentage: utilityBeforeTaxPercentage,
    };

    return newValueForUtility;
  };

  const calculateWithContracts = (newRowState, totalForPercentage) => {
    const findGroupToRecalculate = findBudget(contractRowsForBudgetContol, newRowState.group);

    const itemsForRecalculateGroupValue = findGroupToRecalculate.items.map((item) => item.id);

    const contractsforRecalculateItemValue = findBudget(findGroupToRecalculate.items, newRowState.item).contracts.map(
      (contract) => contract.id,
    );

    const groupToRecalculate = findBudget(budgetRows, newRowState.group, newRowState.budgetIndex);

    const itemToRecalculate = findBudget(budgetRows, newRowState.item, newRowState.budgetIndex);

    const newValueForItem = calculateTotalAndPercentage(
      contractsforRecalculateItemValue,
      newRowState,
      newRowState.budgetId,
      itemToRecalculate,
      totalForPercentage,
    );

    const newValueForGroup = calculateTotalAndPercentage(
      itemsForRecalculateGroupValue,
      newRowState,
      newRowState.item,
      groupToRecalculate,
      totalForPercentage,
    );

    const newValueForUtility = calculateUtility(newValueForGroup, totalForPercentage);

    return [newValueForGroup, newValueForItem, newValueForUtility];
  };

  const calculateTotalsRows = (newRowState) => {
    const findBudgetToPlus = budgetRows.find(
      (budgetRow) =>
        budgetRow.budgetId !== newRowState.budgetId &&
        budgetRow.budgetIndex === newRowState.budgetIndex &&
        (budgetRow.budgetId === 'TOTAL_ID_FUTURE' || budgetRow.budgetId === 'TOTAL_ID_PAST'),
    );

    const totalBudget = findBudget(budgetRows, newRowState.parentId, newRowState.budgetIndex);

    const totalForTotalBudget = newRowState.previousBudget + findBudgetToPlus.previousBudget;
    const newTotalSalesBudget = {
      ...totalBudget,
      previousBudget: totalForTotalBudget,
    };

    const newValueForUtility = calculateUtility(newTotalSalesBudget, newTotalSalesBudget.previousBudget, true);

    return [newTotalSalesBudget, newValueForUtility];
  };

  const calculateFinancingRows = (newRowState, totalForPercentage) => {
    const findBudgetToPlus = budgetRows.find(
      (budgetRow) =>
        budgetRow.budgetId !== newRowState.budgetId &&
        budgetRow.budgetIndex === newRowState.budgetIndex &&
        (budgetRow.budgetId === 'MONETARY_CORRECTION' || budgetRow.budgetId === 'INTEREST'),
    );

    const financingRow = findBudget(budgetRows, newRowState.parentId, newRowState.budgetIndex);

    const totalForFinancingRow = newRowState.previousBudget + findBudgetToPlus.previousBudget;

    const newFinancingBudget = {
      ...financingRow,
      previousBudget: totalForFinancingRow,
      budgetPercentage: calculatePercentage(totalForFinancingRow, totalForPercentage),
    };

    const newValueForUtility = calculateUtility(financingRow, totalForPercentage);

    return [newFinancingBudget, newValueForUtility];
  };

  const calculateWithoutContracts = (newRowState, totalForPercentage) => {
    if (newRowState.parentId === 'TOTAL_SALES') {
      const newTotalsRows = calculateTotalsRows(newRowState);
      return newTotalsRows;
    }

    if (newRowState.parentId === 'financing') {
      const newFinancingRows = calculateFinancingRows(newRowState, totalForPercentage);
      return newFinancingRows;
    }

    const newValueForUtility = calculateUtility(newRowState, totalForPercentage);

    return [newRowState, newValueForUtility];
  };

  const recalculatedRows = (newRowState, totalForPercentage) => {
    if (
      newRowState.parentId ||
      newRowState.budgetId === 'financialPerformance' ||
      newRowState.budgetId === '-100' ||
      newRowState.budgetId === 'perThousand'
    ) {
      const updatedRows = calculateWithoutContracts(newRowState, totalForPercentage);

      return updatedRows;
    }

    const updatedRows = calculateWithContracts(newRowState, totalForPercentage);
    return updatedRows;
  };

  return {
    calculatePercentage,
    recalculatedRows
  }
}

export default useCalculatePreviousControlRows